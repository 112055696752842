import { useAccountStore } from '~/stores/account'

export const createAccessMiddleware = (scopes: string[]) => {
  return async () => {
    // skip middleware on server
    if (process.server) return

    const { $sdk, $normalizePath } = useNuxtApp()
    const account = await $sdk
      .module('auth')
      .then(({ getAccount }) => getAccount())

    const accountStore = useAccountStore()
    const hasScope = scopes.some(scope => accountStore.hasScope(scope))
    if (!account || !hasScope) {
      const redirectPath = $normalizePath('/')
      return navigateTo(redirectPath)
    }
  }
}
